<template>
  <div>
    <div class="helpCenterBox">
      <h2>帮助中心</h2>
      <h5>全面解决客户遇到的各种问题，保证售后无忧</h5>
    </div>
    <div class="hotTopics">
      <h2>热门问题</h2>
      <div class="clearfix">
        <dl class="icon-animation-box" v-for="(item,index) in links.length>4?4:links.length" :key="index" @click="$router.push({name:'helpList', query: {type:links[index].id}})">
          <dt class="icon-animation" :style="{'background-image': `url(${require('@/assets/icons/helpCenter/help-icon'+(index+1)+'.png')})`}"></dt>
          <dd>{{links[index].categoryName}}</dd>
        </dl>
        <dl class="icon-animation-box" @click="$router.push({name:'helpList', query: {type:links[0].id}})">
          <dt class="icon-animation" :style="{'background-image': `url(${require('@/assets/icons/helpCenter/help-icon5.png')})`}"></dt>
          <dd>更多</dd>
        </dl>
      </div>
    </div>
    <v-networkSecurity />
  </div>
</template>

<script>
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      links: []
    }
  },
  components: {
    'v-networkSecurity': networkSecurity,
  },
  created () {
    this.$api.getContentcategory({ categoryType: 2 }).then(res => {
      this.links = res.data
    })
  }
}
</script>

<style lang="scss" scoped>
.helpCenterBox {
  width: 7.5rem;
  height: 9.8rem;
  padding-top: 2.6rem;
  box-sizing: border-box;
  background: #f0f4ff url('~@/assets/image/helpCenter.png') right bottom
    no-repeat;
  background-size: 6.58rem 3.75rem;
  text-align: center;
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.24rem;
  }
  h5 {
    font-size: 0.28rem;
    font-weight: 400;
    color: #303b50;
    line-height: 0.44rem;
  }
}
.hotTopics {
  padding: 0.9rem 0.38rem 0 0.41rem;
  box-sizing: border-box;
  text-align: center;
  h2 {
    font-size: 0.48rem;
    color: #2d3b52;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
  }
  dl {
    width: 3.18rem;
    height: 2.3rem;
    background: #f9fbff;
    box-shadow: 0px 0.04rem 0.08rem 0px rgba(31, 37, 81, 0.11);
    border-radius: 0.05rem;
    border: 0.04rem solid #ffffff;
    margin: 0 0.34rem 0.24rem 0;
    padding-top: 0.48rem;
    box-sizing: border-box;
    float: left;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  dt {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 auto 0.1rem;
  }
  dd {
    font-size: 0.28rem;
    font-weight: bold;
    color: #303b50;
    line-height: 0.45rem;
  }
}
@keyframes plsFooterLeave {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes plsFooterEnter {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}
.icon-animation-box:hover {
  .icon-animation {
    animation: plsFooterEnter 0.2s steps(5) forwards;
  }
}
.icon-animation {
  height: 60px;
  width: 60px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  animation: plsFooterLeave 0.2s steps(5) forwards;
  &:hover {
    animation: plsFooterEnter 0.2s steps(5) forwards;
  }
}
</style>